// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200..800&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css");

body {
  padding: 0;
  margin: 0;
  background: linear-gradient(116.66deg, #132b41 -2.66%, #1c4967 91.26%);
  color: #ffffff;
  font-family: "Montserrat", sans-serif;
}

.wrapper {
  min-height: 100vh;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 8rem repeat(2, 11rem) repeat(4, 10rem) repeat(2, 6rem);
  @media only screen and (min-width: 768px) {
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(6, 1fr) 4rem;
  }
}

.logo-container {
  grid-column: 2;
  @media only screen and (min-width: 768px) {
    grid-column: 6;
  }
}

.user-photo-section {
  grid-row: 2 / span 2;
  grid-column: 1 / span 3;
  @media only screen and (min-width: 768px) {
    grid-row: 1 / span 5;
    grid-column: 1 / span 2;
  }
  .user-photo-container {
    width: 20rem;
    height: 13rem;
    @media only screen and (min-width: 768px) {
      width: 28rem;
      max-width: 100%;
    }
    .bg-logo {
      z-index: 1;
    }

    .user-photo {
      z-index: 2;
      width: 50%;
      top: 20%;
      right: 35%;

      @media only screen and (min-width: 992px) {
        width: 13rem;
        top: 5.4rem;
        right: 10rem;
        max-width: 46%;
      }
    }
  }
}

.contact-name-section {
  grid-row: 4 / span 2;
  grid-column: 1 / span 3;
  @media only screen and (min-width: 768px) {
    grid-row: 2 / span 6;
    grid-column: 3 / span 3;
  }
}

address .icon {
  height: 25px;
  width: 25px;
  display: grid;
  justify-items: center;
}

.head-office {
  grid-row: 6;
  grid-column: 1 / span 3;
  @media only screen and (min-width: 768px) {
    grid-row: 3;
    grid-column: 5 / span 2;
  }
}

.marketing-office {
  grid-row: 7;
  grid-column: 1 / span 3;
  @media only screen and (min-width: 768px) {
    grid-row: 4;
    grid-column: 5 / span 2;
  }
}

.group-qr {
  grid-row: 3;
  grid-column: 1 / span 3;
  @media only screen and (min-width: 768px) {
    grid-row: 2 / span 2;
    grid-column: 1 / span 6;
    & > div {
      width: 70%;
    }
    img {
      width: 20% !important;
    }
  }
  img {
    width: 65%;
  }
}

.group-member-qr {
  grid-row: 5;
  grid-column: 1 / span 3;
  & > div {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  h3 {
    font-size: 0.852rem;
  }
  @media only screen and (min-width: 768px) {
    grid-row: 4 / span 2;
    grid-column: 1 / span 6;
    & > div {
      width: 70%;
    }

  }

  .member-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    @media only screen and (min-width: 768px) {
      height: 3.5rem;
      gap: 1rem;
    }
  }
}

.group-member {
  grid-row: 8;
  grid-column: 1 / span 3;
  & > div {
    box-shadow: 0px 4px 4px 0px #00000040;
  }
  h3 {
    font-size: 0.852rem;
  }
  @media only screen and (min-width: 768px) {
    grid-row: 5 / span 2;
    grid-column: 1 / span 6;
    & > div {
      width: 70%;
    }

  }

  .member-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 40px;
    @media only screen and (min-width: 768px) {
      height: 3.5rem;
      gap: 1rem;
    }
  }
}

.corner-frame {
  bottom: 0;
  right: 0;
  width: 80px;

  @media only screen and (min-width: 768px) {
    /* For desktop: */
    width: 150px;
  }
}
